.parentContent {
  flex: 1;
  max-width: 100%;
  width: 100%;
}

.wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 12px
}

.list {
    display: grid;
    grid-template-columns: 1fr;
    padding: 16px;
    gap: 8px;
    justify-content: center;
    width: 100%;
    max-width: 738px;
}

.card {
    display: grid;
    padding: 8px;
    grid-template-columns: 80px 1fr;
    gap: 8px;
    background: #ffffff;
    border-radius: 4px;
    align-items: center;
    width: 100%;
    color: var(--default-color);
    text-decoration: none;
}

.link {
    text-decoration: none;
}

.link:hover {
    text-decoration: none;
}

.card a {
    text-decoration: none;
}

.card .img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.content p  {
    font-size: 14px;
    margin: 0;
}

.title {
    display: flex;
    align-items: center;
    gap: 4px;

}

.title h2 {
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    color: var(--default-color);
    text-decoration: none;
}

.subheader {
    font-size: 16px;
    text-align: center;
    margin: 0;
}

.back {
  margin: 12px 16px 0;
}

.back img {
  height: 8px;
  width: 8px;
  margin-right: 4px;
  color: #ffffff;
}

.header {
    --overlap-margin: -20px;
    background-color: var(--marriot-orange);
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: hidden;
    width: 100%;
}



.header .icon {
    background: var(--default-color);
    color: #ffffff;
    border-radius: 50%;
    border: 4px solid var(--marriot-orange);
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: var(--overlap-margin);
}

.icon.love {
    padding: 27px;
}

.icon.like {
    padding: 20px;
}

.icon:first-child {
    margin-left: 0;
}

.icon:last-child {
    margin-right: 0;
}

.love img {
    height: 56px;
    width: 56px;
}

.like img {
    height: 40px;
    width: 40px;
}

@media (min-width: 768px) {
    .list {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 600px) {
    [data-passions="5"], [data-passions="6"], [data-passions="7"] {
        --overlap-margin: -30px;
    }
}
