.header {
    padding: 24px 16px 8px;
}

.header h1 {
  padding-top: 0;
  padding-bottom: 4px;
}

.subheader {
    font-size: 16px;
    text-align: center;
    margin: 0;
}

.imgContainer {
    display: flex;
    justify-content: center;
    padding: 16px;
}

.img {
    max-width: 360px;
    max-height: 300px;
    height: 100%;
    width: 100%;
    border-radius: 4px;
}

.buttonContainer {
    padding: 16px 16px 48px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}

.yesBtn, .noBtn {
    display:flex;
    text-decoration: none;
    vertical-align: middle;
    border-radius: 30px;
    border: 1px solid #1c1c1c;
    font-size: 14px;
    font-weight: bold;
    height: 32px;
    align-items: center;
    justify-content: center;
    padding: 20px 16px;
    text-align: center;
    color: #FFFFFF;
    background: #1C1C1C;
    width: 100%;
    max-width: 320px;
}

.noBtn {
    background: transparent;
    border: 0;
    color: var(--default-color);
}

.explore:disabled {
    opacity: 0.5;
}
