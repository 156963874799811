.welcome-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: #faf6f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.bg-white {
  margin: 50px 0;
  width: 100%;
  max-width: 988px;
  height: 100%;
  max-height: 682px;
  background: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.bg-white-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.bg-white .logo {
  display: block;
  margin: 0 auto;
  padding-top: 0;
  max-width: 200px;
  height: auto;
  width: 100%;
  object-fit: contain;
}

.error-title {
  font-size: 40px;
  font-weight: 400;
  word-wrap: break-word;
  color: #1c1c1c;
  text-align: center;
  margin-top: 40px;
}

.error-text {
  font-size: 16px;
  font-weight: 400;
  color: #707070;
  text-align: center;
  word-wrap: break-word;
  line-height: 32px;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 0 40px;
}
