.container {
  position: relative;
  overflow: hidden;
  background-color: #0000001a;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
