.list {
    display: flex;
    gap: 16px;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 20px;
}

h1 {
  padding-top: 24px;
  padding-bottom: 4px;
}

.subheader {
    font-size: 16px;
    padding-bottom: 24px;
    text-align: center;
}
