/*
 * Fonts
 */
/* General & Desktop */
:root {
  --font-family-default: "Swiss", "Arial", sans-serif;

  --font-body: normal 400 16px/24px var(--font-family-default);
  --font-heading: normal 600 30px/40px var(--font-family-default);
  --font-footer: normal 400 13px/20px var(--font-family-default);
  --font-button: normal 600 16px/24px var(--font-family-default);
  --font-button-sm: normal 600 13px/20px var(--font-family-default);
}

/* Mobile */
@media (max-width: 457px) {
  :root {
    --font-heading: normal 600 22px/28px var(--font-family-default);
  }
}

/*
 * Colors
 */
:root {
  --color-black: #1c1c1c;
  --color-white: #ffffff;
  --color-dark: #303030;
  --color-light: #f9f6f4;
  --color-primary: #ff8d6b;
}

/*
 * Others
 */
:root {
  --radius-default: 8px;
}
