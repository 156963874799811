.login-container {
  width: 100%;
  height: 100vh;
  padding: 88px 80px 260px 80px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.logo {
  width: 132px;
  height: auto;
  object-fit: contain;
}

.welcome-title {
  color: #1C1C1C;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
}

.signin-title {
  color: #1C1C1C;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.form-field-container {
  width: 458px;
  position: relative;
  margin-bottom: 20px;
}

.input-label {
  position: absolute;
  left: 16px;
  top: -10px;
  background: white;
  color: #707070;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 0 5px;
}

.input-field {
  width: 100%;
  height: 46px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #707070;
  box-sizing: border-box;
  font-size: 14px;
}

.beta-note {
  color: #888;
  font-size: 0.9em;
  margin-top: 10px;
}

.signin-btn {
  width: 132px;
  height: 40px;
  background: #1C1C1C;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.error {
  color: red;
  margin-bottom: 10px;
}

.forgot-password {
  color: #1C1C1C;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  line-height: 20px;
  cursor: pointer;
}

@font-face {
  font-family: 'MiIcons';
  src: url('https://cache.marriott.com/aka-fonts/mi-icons/mi-icons.woff2') format('woff2'),
       url('https://cache.marriott.com/aka-fonts/mi-brand-logo-icons/mi-brand-logo-icons.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

.password-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-field {
  flex-grow: 1;
}

.trailing-element {
  position: absolute;
  right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-visibility-off:before, .icon-visibility-on:before {
  font-family: 'MiIcons';
}

.icon-visibility-off:before {
  content: "\ea26";
}

.icon-visibility-on:before {
  content: "\e967";
}