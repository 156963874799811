.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 100%;
  background-color: #303030;
}

.survey {
  flex: 1;
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;
}

.surveyLogo {
  height: 32px;
}

.surveyBox {
  margin-top: 32px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 352px;
  aspect-ratio: 0.5;
}

.surveyVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: var(--radius-default);
}

.surveyInfo {
  position: relative;
  padding: 0 16px 24px;
  border-radius: var(--radius-default);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.45) 25%,
    rgba(0, 0, 0, 0.75) 100%
  );
}

.surveyIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #ff8d6b;
  color: #1c1c1c;
}

.surveyText {
  margin-top: 12px;
  font: var(--font-body);
  font-weight: 600;
  color: #ffffff;
}

.surveyPassion {
  font: var(--font-body);
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
}

.surveyBtnGroup {
  margin-top: 24px;
  display: flex;
  gap: 16px;
}

.surveyBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 30px;
  font: var(--font-button);
  background-color: #ffffff;
  color: #1c1c1c;
}

.surveyBtnIcon {
  margin-left: 4px;
  width: 24px;
  height: 24px;
}

.surveySkip {
  margin-top: 16px;
  padding: 4px;
  font: var(--font-button);
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
}

.surveySkipIcon {
  margin-left: 8px;
}

@media (max-width: 393px) {
  .container {
    justify-content: flex-start;
    background-color: #1c1c1c;
  }

  .surveyVideo,
  .surveyInfo {
    border-radius: 0;
  }

  .survey {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
  }

  .surveyBox {
    margin-top: 0;
    width: 100%;
  }

  .surveyLogoWrapper {
    position: absolute;
    padding-top: 64;
    padding-bottom: 16px;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 1;
  }
}
