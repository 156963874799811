.footer {
  width: 100%;
  padding: 16px;
  text-align: center;
  background-color: #1c1c1c;
  color: #ffffff;
  cursor: default;
}

.rights {
  margin: 0;
  font: var(--font-footer);
}

.language {
  margin-top: 8px;
  font: var(--font-footer);
  text-decoration: underline;
}

.privacyChoicesImg {
  display: inline-block;
  margin-top: -3px;
  margin-right: 4px;
}
